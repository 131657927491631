/** @jsx jsx */

import React, { useRef, useState } from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Box from "./box"
import Icon from "./icon"

const Checkbox = ({ children, space, defaultChecked, ...restProps }) => {
  return (
    <Box as="label" sx={{ display: "inline-block" }}>
      <Box
        as="input"
        type="checkbox"
        {...restProps}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      />
      <Box
        sx={{
          bg: "transparent",
          borderWidth: 1,
          borderColor: "whisper",
          borderStyle: "dashed",
          borderRadius: "9999em",
          display: "inline-flex",
          fontFamily: "sans",
          color: "text",
          cursor: "pointer",
          p: 3,
          variant: "forms.controls",
          "input:checked ~ &": {
            borderColor: "contrast",
            bg: "contrast",
            color: "background",
          },
          "input:checked ~ & .checkbox-button-icon": {
            display: "inline-block",
          },
        }}
      >
        <Box className="checkbox-button-icon" sx={{ mr: 2, display: "none" }}>
          <Icon icon="done" />
        </Box>
        {children}
      </Box>
    </Box>
  )
}

Checkbox.propTypes = {
  defaultChecked: PropTypes.bool,
}

Checkbox.defaultProps = {
  space: 2,
  defaultChecked: false,
}

export default Checkbox
