import React from "react"
import { navigate } from "gatsby"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import Inline from "../../../../../components/inline"
import Note from "../../../../../components/note"
import CheckboxButton from "../../../../../components/checkbox-button"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "jahreszeiten",
          chapterId: "01-jahreszeitengedicht",
          taskId: "lesen-und-einpraegen",
        })
        navigate(
          "/kurse/jahreszeiten/01-jahreszeitengedicht/lesen-und-einpraegen/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" name="" value="submit" />}
    >
      <Seo title="Lesen & Einprägen" />

      <Stack space={10}>
        <Heading as="h1" level={2}>
          Lesen & Einprägen
        </Heading>
        <Note variant="task">
          Markiere die Wörter, die im Gedicht vorkommen, indem du sie anklickst.
          Klicke erneut darauf, wenn du die Markierung wieder entfernen
          möchtest.
        </Note>
        <Inline>
          <CheckboxButton name="gesang" value="gesang">
            Gesang
          </CheckboxButton>
          <CheckboxButton name="natur" value="natur">
            Natur
          </CheckboxButton>
          <CheckboxButton name="tiefe" value="tiefe">
            Tiefe
          </CheckboxButton>
          <CheckboxButton name="sonne" value="sonne">
            Sonne
          </CheckboxButton>
          <CheckboxButton name="blumen" value="blumen">
            Blumen
          </CheckboxButton>
          <CheckboxButton name="wolken" value="wolken">
            Wolken
          </CheckboxButton>
          <CheckboxButton name="harmonie" value="harmonie">
            Harmonie
          </CheckboxButton>
          <CheckboxButton name="freude" value="freude">
            Freude
          </CheckboxButton>
          <CheckboxButton name="schatten" value="schatten">
            Schatten
          </CheckboxButton>
          <CheckboxButton name="daemmer" value="daemmer">
            Dämmer
          </CheckboxButton>
          <CheckboxButton name="reben" value="reben">
            Reben
          </CheckboxButton>
          <CheckboxButton name="mensch" value="mensch">
            Mensch
          </CheckboxButton>
          <CheckboxButton name="himmel" value="himmel">
            Himmel
          </CheckboxButton>
        </Inline>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
